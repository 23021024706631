<template>
    <public-invoicable-view :loading="loading" :invoicable="invoicable" :error-message="errorMessage">
        <template #menu>
            <v-btn v-if="canShowPaymentButton" depressed color="primary" @click="openPaymentLink">
                {{ $t('general.payInvoice') }}
            </v-btn>
        </template>
    </public-invoicable-view>
</template>

<script>
import { getInvoiceByUUID } from '@/services/invoice';

import PublicInvoicableView from './PublicInvoicableView';

export default {
    components: {
        PublicInvoicableView
    },

    data() {
        return {
            invoicable: null,
            loading: true,
            errorMessage: null
        };
    },

    computed: {
        canShowPaymentButton() {
            if (!this.invoicable) {
                return false;
            }

            return this.invoicable.payed === false && this.invoicable.paymentLink;
        }
    },

    async created() {
        this.loading = true;
        try {
            this.invoicable = await getInvoiceByUUID(this.$route.params.uuid);
        } catch (e) {
            this.errorMessage = this.$t('errors.cannotFindDocument');
            this.loading = false;
            return window.location.replace('https://dexxter.be');
        } finally {
            this.loading = false;
        }
    },

    methods: {
        openPaymentLink() {
            window.location.href = this.invoicable.paymentLink;
        }
    }
};
</script>
